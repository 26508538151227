@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

* {
    text-decoration: none !important;
    outline: none !important;
}

.ff-1 {
    font-family: 'Permanent Marker', cursive;
}

.ff-2 {
    font-family: 'Courgette', cursive;
}

/* 
.ff-3 {
    font-family: 'Bellefair', serif;
}  */

.cover {
    object-fit: cover;
}

.pointer {
    cursor: pointer;
}

/* Galery Images */

.react-photo-gallery--gallery img {
    object-fit: cover;
    transition: 0.1s;
    padding: 5px !important;
    border-radius: 8px;
}

.react-photo-gallery--gallery img:hover {
    filter: brightness(0.6)
}

.react-images__navigation button {
    background-color: transparent !important;
}

@media screen and (min-width: 520px) {

    /* Galery Images */
    .react-images__view-image {
        width: auto;
        height: 95vh !important;
    }
}

.hertz svg {
    font-size: 200pt !important;
    color: #006837 !important;
}

.bestellButton {
    position: fixed;
    bottom: 3%;
    right: 3%;
    z-index: 100;
    border: none;
    background-color: #078065;
    padding: 10px;
    color: white;
    border-radius: 30px;
    transition: 0.2s;
    box-shadow: 0px 2px 10px 1px black;
}

.bestellButton:hover {
    transform: scale(1.1);
    background-color: #409445;
}

.bestellButton:active {
    background-color: #4cad53;
}

.bestellButton {
    animation: bestellButton 4s infinite ease-in-out, delay-animation 5s linear infinite;
}

@keyframes bestellButton {
    0% {
        transform: scale(1);
    }

    80% {
        transform: scale(1);
    }

    90% {
        transform: scale(1.1);
    }

    10% {
        transform: scale(1);
    }
}

.startContainer img {
    filter: brightness(0.5) !important;
}

.startContainer * {
    z-index: -10000 !important;
}

.startContainerText {
    margin-top: -450px !important;
    margin-bottom: 220px;
}

.startContainerText * {
    z-index: 100 !important;
    text-shadow: 0px 0px 3px black;
}

@media screen and (max-width: 420px) {
    .startContainerText {
        margin-bottom: 100px;
    }
}

.test svg {
    margin-left: auto;
    font-size: 200pt !important;
}

.footer {
    background-color: #393939;
}

.footer a {
    color: white;
}

.footer a:hover {
    color: gray;
}

.terevDiv {
    background-image: url(./image//start//terev.png);
    background-size: cover;
}

/* 
@media screen and (max-width: 420px) {
    .terevDiv {
        background-image: url(./image//start//terev.png);
        background-size: cover;
    }
} */

.terevDiv p {
    text-shadow: 0px 0px 4px white;
}

.kontaktIcon svg {
    font-size: 100pt !important;
}

.kontaktInput input {
    margin-top: 10px;
    background-color: white;
    border: 1px solid gray;
    padding: 10px 10px;
    width: 100%;
    border-radius: 10px;
}

.kontaktInput textarea {
    margin-top: 10px;
    background-color: white;
    border: 1px solid gray;
    padding: 10px 10px;
    width: 100%;
    border-radius: 10px;
}

.kontaktInput button {
    border-radius: 10px;
    margin-top: 10px;
    background-color: rgb(7, 128, 101);
    border: 1px solid gray;
    padding: 10px 10px;
    width: 100%;
    color: white;
    transition: 0.1s;
}

.kontaktInput button:hover {
    background-color: rgb(11, 168, 134);
    transform: scale(1.001);
}

.kontaktInput button:active {
    background-color: rgb(24, 177, 144);
}

.MuiTabs-indicator {
    background-color: rgb(7, 128, 101) !important;
    border-color: rgb(7, 128, 101) !important;
}

.nummer button {
    border-radius: 10px;
    margin-top: 10px;
    background-color: rgb(7, 128, 101);
    border: 1px solid gray;
    padding: 10px 10px;
    width: 100%;
    color: white;
    transition: 0.1s;
    font-size: 3vw;
}

.nummer button:hover {
    background-color: rgb(11, 168, 134);
    transform: scale(1.001);
}

.nummer button:active {
    background-color: rgb(24, 177, 144);
}

.menuButton {
    position: fixed;
    z-index: 100;
    background-color: #393939;
    padding: 20px;
    left: 2%;
    top: 2%;
    color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 2px 0px gray;
    transition: 0.1s;
    cursor: pointer;
}

.menuButton:hover {
    transform: scale(1.1);
}

.menuButton:active {
    background-color: rgb(11, 151, 121);
    ;
}

@media screen and (max-width: 420px) {
    .drawerImg {
        width: 100px;
    }
}

.untenSeite {
    background-color: rgb(7, 128, 101);
}

.erro .MuiSnackbarContent-root {
    background-color: brown !important;
}

.info .MuiSnackbarContent-root {
    background-color: rgb(19, 109, 69) !important;
}

.dunkel {
    background-color: rgb(235, 235, 235);
}

.warenkorb {
    position: fixed;
    z-index: 100;
    right: 2%;
    top: 10%;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
}

.text-successs {
    color: rgb(19, 109, 69) !important;
}

.bg-successs {
    background-color: rgb(19, 109, 69) !important;
}

.article {
    background-color: rgb(235, 235, 235);
    box-shadow: 2px 2px 7px 0px grey;
}

.noBreak {
    white-space: nowrap !important;
}

.searchContainer {
    border-bottom: 1px solid rgb(173, 173, 173);
    border-radius: 2px;
    padding: 0 5px;
    display: flex;
}

.searchContainer input {
    border: none;
    padding: 5px;
    width: auto;
    flex: 1;
}

.searchContainer svg {
    color: gray;
    margin: auto 0 auto 0;
}

.menuItem {
    font-size: 5vw;
}

.badges {
    position: absolute;
    display: flex;
    left: 10px;
    gap: 6px;
    top: -5px;
    font-size: 12px;
    font-family: sans-serif;
    z-index: 10;
    padding-left: 10px;
}

.badges div {
    padding: 2px 4px;
    letter-spacing: 1px;
    border-radius: 2px;
}